import Tooltip from '@/components/Tooltip';
import Vue from 'vue';
import VuePerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import VueMeta from 'vue-meta';
import VueMoment from 'vue-moment';
import VueScrollTo from 'vue-scrollto';
import VueShortkey from 'vue-shortkey';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueWindowSize from 'vue-window-size';

import App from './App.vue';
import './assets/tailwind.css';
import i18n from './i18n';
import router from './router';
import store from './store';

Vue.component('Tooltip', Tooltip);

Vue.use(VueMoment);
Vue.prototype.moment = VueMoment;

Vue.use(VueMeta);
Vue.use(VuePerfectScrollbar);
Vue.use(VueScrollTo);
Vue.use(VueShortkey);
Vue.use(VueVirtualScroller);
Vue.use(VueWindowSize);

Vue.mixin({
  methods: {
    scrollToSegment(id, history = false) {
      let segmentIndex;
      if (history) {
        segmentIndex = this.$store.state.subtitles.findIndex((subtitle) => subtitle.uid === id);
      } else {
        segmentIndex = this.$store.state.subtitles.findIndex((subtitle) => subtitle.id === id);
      }

      if (segmentIndex < 0) return;

      const scroller = document.getElementById('timeline-wrapper');
      scroller.__vue__.scrollToItem(segmentIndex);
    },
  },
});

store.i18n = i18n;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
