<template>
  <div id="app">
    <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
    <transition name="fade-router-view" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      viewportContent: 'width=1024',
    };
  },
  metaInfo() {
    return {
      title: 'Subtitles editor · SlidesLive',
      meta: [
        { name: 'viewport', content: this.viewportContent },
        { name: 'author', content: 'SlidesLive' },
        {
          name: 'description',
          content:
            'Subtitles Editor for SlidesLive presentations. Initially created by Bohemica Studio, further development has been done by SlidesLive.',
        },
      ],
    };
  },
};
</script>

<style></style>
