import axios from 'axios';
import FileSaver from 'file-saver';
import { parseSync, stringifySync } from 'subtitle';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    editorState: 'loading-presentation',

    presentationId: null,
    subtitlesEditorToken: null,
    presentationSubtitlesId: null,

    editorData: null,
    presentationSubtitlesData: null,

    selectedSubtitlesSegmentId: null,

    firstLoad: true,
    subtitles: [],
    id: 1,
    uid: 1,
    embededVideo: null,
    active: null,
    showSaveConfirmModal: false,
    showSettings: false,
    showModal: false,
    showHistory: false,
    showWarnings: false,
    modal: {
      title: '',
      text: '',
      type: 'success',
      callback: null,
      buttonOk: {
        display: true,
        text: 'OK',
      },
      buttonCustom: {
        display: false,
        text: '',
      },
      buttonCancel: {
        display: true,
        text: 'Cancel',
      },
    },
    editing: false,
    settings: {
      subtitlesOverlap: false,
      subtitlesOverlapChangeNextSegment: true,
      subtitlesMinimumLengthPerCharacter: 10,
      savedInfoDialog: true,
      autoScroll: true,
      historyMaxLimit: 100,
    },
    history: [], // {id: Number, type: 'remove'|'create'|'merge'|'update'|'resync', segmetntId:null|Number, data:{}, date:Date}
    historyId: 0,
    currentHistoryStep: null,
    activeItemPreviousSegment: {},
    displayUnsavedOnly: false,
    warnings: {},
    currentTime: 0,
  },
  mutations: {
    setPresentationIdNew(state, { presentationId, subtitlesEditorToken }) {
      state.presentationId = presentationId;
      state.subtitlesEditorToken = subtitlesEditorToken;
    },

    setEditorData(state, editorData) {
      state.editorData = editorData;
    },

    setPresentationSubtitlesId(state, { presentationSubtitlesId }) {
      state.presentationSubtitlesId = presentationSubtitlesId;
    },

    setPresentationSubtitlesData(state, presentationSubtitlesData) {
      state.presentationSubtitlesData = presentationSubtitlesData;
    },

    setCustom(state, { variable, value }) {
      console.log('SET CUSTOM', variable, state[variable], value);
      state[variable] = value;
    },
    setSubtitle(state, payload) {
      const subtitles = [];
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].type === 'header' || payload[i].type === undefined) {
          continue;
        }
        const start = payload[i].data.start;
        const end = payload[i].data.end;
        const text = payload[i].data.text;
        subtitles.push({
          id: state.id++,
          saved: true,
          uid: state.uid++,
          start: start,
          end: end,
          text: text,
        });
      }
      state.subtitles = subtitles;
    },
    createPotentialSegment(state, payload) {
      const subtitles = state.subtitles.filter((segment) => !segment.potential);

      subtitles.sort((a, b) => {
        if (a['start'] > b['start']) {
          return 1;
        }
        if (a['start'] < b['start']) {
          return -1;
        }
        return 0;
      });

      subtitles.splice(payload.order + 1, 0, {
        id: state.id++,
        end: state.subtitles[payload.order].end,
        start: state.subtitles[payload.order].end,
        text: '',
        potential: true,
        saved: true,
        uid: state.uid++,
      });

      state.subtitles = subtitles;
      state.editing = true;
    },
    removePotentialSegments(state) {
      state.subtitles = state.subtitles.filter((segment) => !segment.potential);
      state.editing = true;
    },
    savePotentialSegment(state, order) {
      for (const segment of state.subtitles) {
        if (segment.potential) {
          segment.potential = false;
          segment.saved = false;
        }
      }
      state.subtitles[order + 1].potential = false;
      state.subtitles[order + 1].saved = false;
      state.editing = true;
    },
    removeSegment(state, id) {
      let index = state.subtitles.findIndex((x) => x.id === id);
      this.commit('resetWarningsFor', state.subtitles[index].uid);
      state.subtitles.splice(index, 1);
      state.editing = true;
    },
    createSegment(state, afterId) {
      let index = state.subtitles.findIndex((x) => x.id === afterId);
      state.subtitles.splice(index, 0, {
        id: state.id++,
        end: state.subtitles[index].end + 1,
        start: state.subtitles[index].end + 1,
        text: '',
        saved: false,
        uid: state.uid++,
      });
    },
    mergeSegments(state, segments) {
      let indexSource = state.subtitles.findIndex((x) => x.id === segments.source);
      let indexMerge = state.subtitles.findIndex((x) => x.id === segments.merge);
      let end = state.subtitles[indexMerge].end;
      let text = state.subtitles[indexMerge].text;
      this.commit('resetWarningsFor', state.subtitles[indexMerge].uid);
      this.commit('resetWarningsFor', state.subtitles[indexSource].uid);

      state.subtitles.splice(indexMerge, 1);
      state.subtitles.splice(indexSource, 1, {
        id: state.id++,
        end: end,
        start: state.subtitles[indexSource].start,
        text: (state.subtitles[indexSource].text.trim() + ' ' + text.trim()).trim(),
        saved: false,
        uid: state.uid++,
      });
      console.log('merged', state.subtitles[indexSource]);
      state.editing = true;
    },
    updateSegment(state, subtitle) {
      let index = state.subtitles.findIndex((x) => x.id === subtitle.id);
      state.subtitles.splice(index, 1, {
        id: subtitle.id,
        end: subtitle.end,
        start: subtitle.start,
        text: subtitle.text,
        uid: subtitle.uid,
      });
    },
    addSegmentToCurrentTime(state, time) {
      state.subtitles.push({
        id: state.id++,
        end: time,
        start: time,
        text: '',
        saved: false,
        uid: state.uid++,
      });
      state.editing = true;
    },

    setSelectedSubtitlesSegmentId(state, { id, uid }) {
      let selectedSegment;
      if (Number.isFinite(uid)) {
        selectedSegment = state.subtitles.find((segment) => segment.uid === uid);
      } else if (Number.isFinite(id)) {
        if (Number.isFinite(state.selectedSubtitlesSegmentId)) {
          const before = state.selectedSubtitlesSegmentDataClone;
          const after = state.subtitles.find((segment) => segment.id === state.selectedSubtitlesSegmentId);

          if (
            before &&
            after &&
            (before.text !== after.text || before.start !== after.start || before.end !== after.end)
          ) {
            after.id = state.id++;
            after.uid = state.uid++;
            after.saved = false;

            state.editing = true;

            this.commit('saveToHistory', { type: 'update', old: { ...before }, new: { ...after } });
          }
        }

        selectedSegment = state.subtitles.find((segment) => segment.id === id);
      } else {
        state.selectedSubtitlesSegmentId = null;
      }

      state.selectedSubtitlesSegmentId = selectedSegment ? selectedSegment.id : null;
      state.selectedSubtitlesSegmentDataClone = selectedSegment ? { ...selectedSegment } : null;
    },

    resyncSubtitles(state, ms) {
      const subtitles = [];

      for (const subtitle of state.subtitles) {
        subtitles.push({
          id: subtitle.id,
          end: subtitle.end + ms,
          start: subtitle.start + ms,
          text: subtitle.text,
          saved: subtitle.saved,
          uid: subtitle.uid,
        });
      }

      subtitles.sort((a, b) => {
        if (a['start'] > b['start']) {
          return 1;
        }
        if (a['start'] < b['start']) {
          return -1;
        }
        return 0;
      });

      for (let i = 0; i < subtitles.length; i++) {
        subtitles[i].id = state.id++;
        subtitles[i].saved = false;
        subtitles[i].start = subtitles[i].start < 0 ? 0 : subtitles[i].start;
        subtitles[i].end = subtitles[i].end < 0 ? 0 : subtitles[i].end;
      }

      state.subtitles = subtitles;
      state.editing = true;
    },
    saveSubtitlesDataToLocalStorage(state) {
      localStorage['subtitles_' + state.presentationId + '_' + state.presentationSubtitlesData.language] =
        JSON.stringify({
          subtitlesSegments: state.subtitles,
        });
    },
    deleteSubtitlesDataFromLocalStorage(state) {
      localStorage.removeItem(['subtitles_' + state.presentationId + '_' + state.presentationSubtitlesData.language]);
    },
    loadSubtitlesDataFromLocalStorage(state) {
      const savedData = JSON.parse(
        localStorage['subtitles_' + state.presentationId + '_' + state.presentationSubtitlesData.language],
      );

      state.subtitles = savedData.subtitlesSegments;
      state.id = state.subtitles.reduce((a, b) => Math.max(a.id, b.id), 0) + 1;
      state.uid = state.subtitles.reduce((a, b) => Math.max(a.uid, b.uid), 0) + 1;

      state.editing = true;
    },
    exportSubtitles(state, name) {
      const subtitles = [...state.subtitles].sort((a, b) => {
        if (a['start'] > b['start']) {
          return 1;
        }
        if (a['start'] < b['start']) {
          return -1;
        }
        return 0;
      });

      var blob = new Blob(
        [
          stringifySync(
            [
              {
                type: 'header',
                data: 'WEBVTT',
              },
              ...subtitles.map((el) => {
                return {
                  type: 'cue',
                  data: {
                    start: el.start,
                    end: el.end,
                    text: el.text,
                  },
                };
              }),
            ],
            { format: 'WebVTT' },
          ),
        ],
        { type: 'text/vtt;charset=utf-8' },
      );
      FileSaver.saveAs(blob, `${name}.vtt`);
    },

    setVideo(state, video) {
      state.embededVideo = video;
    },

    setSaveModal(state, value) {
      state.showSaveConfirmModal = value;
    },
    showHistory(state, value) {
      state.showHistory = value;
    },
    showWarnings(state, value) {
      state.showWarnings = value;
    },
    displayModal(state, value) {
      state.showModal = value;
    },
    setModalData(state, data) {
      state.modal = data;
    },
    displaySettings(state, value) {
      state.showSettings = value;
    },
    resetEditing(state) {
      state.editing = false;
    },
    setEditing(state) {
      state.editing = state;
    },
    setPresentationId(state, id) {
      state.presentationId = id;
    },
    initSettings(state) {
      if (localStorage.settings) {
        state.settings = JSON.parse(localStorage.settings);
      }
    },
    setSettingsPreference(state, preference) {
      state.settings[preference.key] = preference.value;
      localStorage.settings = JSON.stringify(state.settings);
    },

    skipBy(state, ms) {
      state.embededVideo.seekTo(state.embededVideo.currentTime() + ms);
    },
    isFirstLoad(state, value) {
      state.firstLoad = value;
    },
    resetUnsaved(state) {
      const subtitles = state.subtitles;
      for (let i = 0; i < subtitles.length; i++) {
        subtitles[i].id = state.id++;
        subtitles[i].saved = true;
      }

      state.subtitles = subtitles;
    },
    setUnsavedFilter(state, display) {
      state.displayUnsavedOnly = display;
    },
    addWarning(state, warning) {
      if (typeof state.warnings[warning.uid] !== 'undefined') {
        for (let i = 0; i < state.warnings[warning.uid].length; i++) {
          if (warning.type == state.warnings[warning.uid][i].type) {
            return;
          }
        }
        state.warnings[warning.uid].push(warning);
      } else {
        state.warnings[warning.uid] = [];
        state.warnings[warning.uid].push(warning);
      }
    },
    resetWarningsFor(state, uid) {
      if (typeof state.warnings[uid] !== 'undefined') {
        delete state.warnings[uid];
      }
    },
    resetWarningTypeFor(state, warning) {
      if (typeof state.warnings[warning.uid] !== 'undefined') {
        state.warnings[warning.uid] = state.warnings[warning.uid].filter((x) => x.type != warning.type);
        if (state.warnings[warning.uid].length == 0) {
          this.commit('resetWarningsFor', warning.uid);
        }
      }
    },

    saveToHistory(state, history) {
      if (state.currentHistoryStep != null) {
        state.history = state.history.slice(0, state.currentHistoryStep);
        state.currentHistoryStep = null;
      }

      let segmentId = null;
      let data = {};
      let index = history.segmentId != undefined ? state.subtitles.findIndex((x) => x.id === history.segmentId) : null;
      switch (history.type) {
        case 'remove':
          data = state.subtitles[index];
          break;
        case 'update':
          data = { old: history.old, new: history.new };
          break;
        case 'create':
          segmentId = state.id + 1;
          data = { time: (state.subtitles[index].end || 0) + 1, uid: state.uid + 1 };
          break;
        case 'savePotential':
          segmentId = state.subtitles[history.segmentPotentialId].id;
          data = { time: state.subtitles[history.segmentPotentialId].start, uid: state.uid + 1 };
          history.type = 'create';
          break;
        case 'createAtTime':
          segmentId = state.id + 1;
          data = { time: history.time, uid: state.uid + 1 };
          history.type = 'create';
          break;
        case 'merge':
          segmentId = state.id;
          data = {
            source: state.subtitles[index],
            merge: state.subtitles[state.subtitles.findIndex((x) => x.id === history.mergeSegmentId)],
            uid: state.uid,
          };
          break;
        case 'resync':
          data = { ms: history.resyncMs };
          break;
      }
      state.history.push({
        id: state.historyId++,
        type: history.type,
        segmentId: segmentId,
        data: data,
        date: new Date(),
      });
      if (state.history.length > state.settings.historyMaxLimit) {
        state.history.shift();
      }
    },
    undo(state) {
      if (state.currentHistoryStep == null) {
        state.currentHistoryStep = state.history.length;
      }
      state.currentHistoryStep--;
      let history = state.history[state.currentHistoryStep];
      switch (history.type) {
        case 'remove':
          history.data.time != undefined
            ? state.subtitles.push({
                id: history.segmentId,
                end: history.data.time,
                start: history.data.time,
                text: '',
                potential: false,
                saved: false,
                uid: history.data.uid,
              })
            : state.subtitles.push(history.data);
          break;
        case 'update':
          let i = state.subtitles.findIndex((x) => x.id === history.data.new.id);
          state.subtitles.splice(i, 1, {
            id: history.data.old.id,
            end: history.data.old.end,
            start: history.data.old.start,
            text: history.data.old.text,
            uid: history.data.old.uid,
            potential: false,
            saved: false,
          });
          break;
        case 'create':
          i = state.subtitles.findIndex((x) => x.id === history.segmentId);
          this.commit('resetWarningsFor', state.subtitles[i].uid);
          this.commit('removeSegment', history.segmentId);
          break;
        case 'merge':
          i = state.subtitles.findIndex((x) => x.uid === history.data.uid);
          this.commit('resetWarningsFor', state.subtitles[i].uid);
          this.commit('removeSegment', state.subtitles[i].id);
          state.subtitles.push(history.data.source);
          state.subtitles.push(history.data.merge);
          break;
        case 'resync':
          this.commit('resyncSubtitles', history.data.ms * -1);
          break;
      }
      this.editing = true;
      this.commit('saveSubtitlesDataToLocalStorage');
    },
    redo(state) {
      let history = state.history[state.currentHistoryStep];
      switch (history.type) {
        case 'remove':
          let i = state.subtitles.findIndex((x) => x.id === history.segmentId);
          this.commit('resetWarningsFor', state.subtitles[i].uid);
          this.commit('removeSegment', history.segmentId);
          break;
        case 'update':
          let index = state.subtitles.findIndex((x) => x.id === history.data.old.id);
          state.subtitles.splice(index, 1, {
            id: history.data.new.id,
            end: history.data.new.end,
            start: history.data.new.start,
            text: history.data.new.text,
            uid: history.data.new.uid,
            potential: false,
            saved: false,
          });
          break;
        case 'create':
          history.data.time != undefined
            ? state.subtitles.push({
                id: history.segmentId,
                end: history.data.time,
                start: history.data.time,
                text: '',
                potential: false,
                saved: false,
                uid: history.data.uid,
              })
            : state.subtitles.push(history.data);
          break;
        case 'merge':
          state.subtitles.push({
            id: history.segmentId,
            end: history.data.merge.end,
            start: history.data.source.start,
            text: (history.data.source.text.trim() + ' ' + history.data.merge.text.trim()).trim(),
            potential: false,
            saved: false,
            uid: history.data.uid,
          });
          i = state.subtitles.findIndex((x) => x.id === history.data.source.id);
          this.commit('resetWarningsFor', state.subtitles[i].uid);
          this.commit('removeSegment', history.data.source.id);
          i = state.subtitles.findIndex((x) => x.id === history.data.merge.id);
          this.commit('resetWarningsFor', state.subtitles[i].uid);
          this.commit('removeSegment', history.data.merge.id);
          break;
        case 'resync':
          this.commit('resyncSubtitles', history.data.ms);
          break;
      }
      state.currentHistoryStep++;
      if (state.currentHistoryStep + 1 > state.history.length) {
        state.currentHistoryStep = null;
      }
      this.editing = true;
      this.commit('saveSubtitlesDataToLocalStorage');
    },
    setCurrentTime(state, time) {
      state.currentTime = time;
    },
  },
  actions: {
    async loadEditorData({ commit }, { presentationId, subtitlesEditorToken }) {
      commit('setEditorData', null);
      commit('setPresentationSubtitlesData', null);
      commit('setSubtitle', []);

      commit('setPresentationIdNew', { presentationId, subtitlesEditorToken });

      const url = `https://studio.slideslive.com/api/presentations/v1/subtitles_editor/${presentationId}?subtitles_editor_token=${subtitlesEditorToken}`;
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json());

      if (!response || !response.success || response.errors) {
        return {
          success: false,
          errors: response.errors || ['Unknown error. Try again or contact SlidesLive support.'],
        };
      }

      commit('setEditorData', response.data);

      return { success: true };
    },

    async loadPresentationSubtitles({ commit, dispatch, state }, { presentationSubtitlesId }) {
      commit('setPresentationSubtitlesId', { presentationSubtitlesId });

      const url = `https://studio.slideslive.com/api/presentations/v1/subtitles_editor/${state.presentationId}?subtitles_editor_token=${state.subtitlesEditorToken}&subtitles_id=${presentationSubtitlesId}`;
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json());

      if (!response || !response.success || response.errors) {
        return {
          success: false,
          errors: response.errors || ['Unknown error. Try again or contact SlidesLive support.'],
        };
      }

      commit('setPresentationSubtitlesData', response.data.subtitles);

      const loadedFromLocalStorage = await dispatch('loadSubtitlesSegmentsFromLocalStorage');
      if (!loadedFromLocalStorage) {
        await dispatch('loadSubtitlesSegmentsFromUrl', { url: response.data.subtitles.webvtt_url });
      }

      return { success: true };
    },

    async loadSubtitlesSegmentsFromLocalStorage({ commit, dispatch, state }) {
      if (!localStorage['subtitles_' + state.presentationId + '_' + state.presentationSubtitlesData.language]) {
        return false;
      }

      return new Promise((resolve) => {
        dispatch('setModalData', {
          title: this.i18n.t('unsavedSubtitles.title'),
          text: this.i18n.t('unsavedSubtitles.text'),
          type: 'info',
          callback: (exitType) => {
            if (exitType == 'ok') {
              commit('loadSubtitlesDataFromLocalStorage');
              resolve(true);
            } else if (exitType == 'cancel') {
              commit('deleteSubtitlesDataFromLocalStorage');
              resolve(false);
            }
          },
          buttonOk: {
            display: true,
            text: this.i18n.t('unsavedSubtitles.load'),
          },
          buttonCancel: {
            display: true,
            text: this.i18n.t('unsavedSubtitles.delete'),
          },
        });

        dispatch('displayModal', true);
      });
    },

    async loadSubtitlesSegmentsFromUrl({ commit }, { url }) {
      const webvtt = await fetch(url).then((response) => response.text());
      const subtitlesSegments = parseSync(webvtt);

      commit('setSubtitle', subtitlesSegments);
    },

    async loadSubtitle(state, data) {
      state.commit('setEditing', true);
      state.commit('setSubtitle', parseSync(data));
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    removePotentialSegments(state) {
      state.commit('removePotentialSegments');
    },

    createPotentialSegment(state, afterId) {
      state.commit('createPotentialSegment', afterId);
    },

    savePotentialSegment(state, afterId) {
      state.commit('saveToHistory', { type: 'savePotential', segmentPotentialId: afterId + 1 });
      state.commit('savePotentialSegment', afterId);
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    removeSegment(state, id) {
      state.commit('saveToHistory', { type: 'remove', segmentId: id });
      state.commit('removeSegment', id);
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    createSegment(state, afterId) {
      state.commit('saveToHistory', { type: 'create', segmentId: afterId, after: true });
      state.commit('createSegment', afterId);
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    updateSegment(state, subtitle) {
      state.commit('updateSegment', subtitle);
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    addSegmentToCurrentTime(state, time) {
      state.commit('saveToHistory', { type: 'createAtTime', time: time || 0 });
      state.commit('addSegmentToCurrentTime', time);
      state.commit('saveSubtitlesDataToLocalStorage');
    },

    setSelectedSubtitlesSegmentId(state, { id, uid }) {
      state.commit('setSelectedSubtitlesSegmentId', { id, uid });
    },

    resyncSubtitles(state, ms) {
      state.commit('saveToHistory', { type: 'resync', resyncMs: ms });
      state.commit('resyncSubtitles', ms);
      state.commit('saveSubtitlesDataToLocalStorage');
    },
    deleteSubtitlesDataFromLocalStorage(state) {
      state.commit('deleteSubtitlesDataFromLocalStorage');
    },
    exportSubtitles(state, name) {
      state.commit('exportSubtitles', name);
    },
    mergeSegments(state, segments) {
      state.commit('saveToHistory', { type: 'merge', segmentId: segments.source, mergeSegmentId: segments.merge });
      state.commit('mergeSegments', segments);
      state.commit('saveSubtitlesDataToLocalStorage');
    },
    resetUnsaved(state) {
      state.commit('resetUnsaved');
    },
    setUnsavedFilter(state, display) {
      state.commit('setUnsavedFilter', display);
    },
    addWarning(state, warning) {
      state.commit('addWarning', warning);
    },
    resetWarningsFor(state, id) {
      state.commit('resetWarningsFor', id);
    },
    resetWarningTypeFor(state, warning) {
      state.commit('resetWarningTypeFor', warning);
    },

    setVideo(state, video) {
      state.commit('setVideo', video);
    },

    setSaveModal(state, value) {
      return state.commit('setSaveModal', value);
    },
    showHistory(state, value) {
      return state.commit('showHistory', value);
    },
    showWarnings(state, value) {
      return state.commit('showWarnings', value);
    },
    displayModal(state, value) {
      return state.commit('displayModal', value);
    },
    setModalData(state, data) {
      return state.commit('setModalData', data);
    },
    displaySettings(state, value) {
      return state.commit('displaySettings', value);
    },
    resetEditing(state) {
      return state.commit('resetEditing');
    },
    setEditing(state) {
      state.commit('setEditing');
    },

    setPresentationId(state, id) {
      return state.commit('setPresentationId', id);
    },

    initSettings(state) {
      return state.commit('initSettings');
    },
    setSettingsPreference(state, preference) {
      return state.commit('setSettingsPreference', preference);
    },

    skipBy(state, ms) {
      state.commit('skipBy', ms);
    },

    isFirstLoad(state, value) {
      return state.commit('isFirstLoad', value);
    },

    undo(state) {
      state.commit('undo');
    },
    redo(state) {
      state.commit('redo');
    },
    goToHistory(state, historySteps) {
      for (let i = 0; i < Math.abs(historySteps); i++) {
        if (historySteps < 0) {
          state.commit('undo');
        } else {
          state.commit('redo');
        }
      }
    },
    setCurrentTime(state, time) {
      state.commit('setCurrentTime', time);
    },
  },
  modules: {},
  getters: {
    getActiveSegmentId: (state) => state.active,

    getVideo: (state) => state.embededVideo,
    isEditing: (state) => state.editing,

    getModalData: (state) => state.modal,

    getHistory: (state) => state.history,
    getHistoryLength: (state) => state.history.length,
    getCurrentHistoryStep: (state) => state.currentHistoryStep,

    displayUnsavedOnly: (state) => state.displayUnsavedOnly,

    getWarnings: (state) => state.warnings,

    settings: (state) => state.settings,

    subtitlesSegments: (state) => {
      return [...state.subtitles].sort((a, b) => {
        if (a['start'] > b['start']) {
          return 1;
        }
        if (a['start'] < b['start']) {
          return -1;
        }
        return 0;
      });
    },

    filteredSubtitlesSegments: (state, getters) => {
      if (!state.displayUnsavedOnly) return getters.subtitlesSegments;

      return getters.subtitlesSegments.filter((segment) => !segment.saved);
    },

    activeSubtitlesSegments: (state, getters) => {
      return getters.subtitlesSegments.filter(
        (segment) => state.currentTime >= segment.start && state.currentTime <= segment.end,
      );
    },

    firstActiveSubtitlesSegmentId: (state, getters) => {
      if (getters.activeSubtitlesSegments.length > 0) return getters.activeSubtitlesSegments[0].id;

      return undefined;
    },

    selectedSubtitlesSegmentId: (state) => {
      return state.selectedSubtitlesSegmentId;
    },
  },
});
