import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'About',
    component: () => import(/* webpackChunkName: "editor" */ '../views/About.vue'),
  },
  {
    path: '/editor',
    redirect: '/',
  },
  {
    path: '/editor/:id',
    name: 'editor',
    component: () => import(/* webpackChunkName: "main_view" */ '../views/MainView.vue'),
  },
];

const router = new VueRouter({
  mode: 'hash', // DO NOT CHANGE! We want to use "#" mode in router because of static deployment server. No 'history' mode in this project. Kuba.
  base: process.env.BASE_URL,
  routes,
});
export default router;
