<template>
  <div
    :class="`text-${color} bg-${background}`"
    class="tooltip rounded-md px-2 text-sm font-semibold opacity-0 shadow-md"
    style="padding-top: 4px; padding-bottom: 5px; z-index: 15"
    v-html="icon ? icon + text : text"
  ></div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: String,
    color: String,
    background: String,
    icon: String,
  },
};
</script>

<style scoped></style>
